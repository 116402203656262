import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'


  Bugsnag.start({ apiKey: 'd0457af0855497e1c4d29c59b55faea5', releaseStage: 'production', enabledReleaseStages: ["production", "staging"], appVersion: 'dev', logger: null, plugins: [new BugsnagPluginReact()]})


// Support component names relative to this directory:
const componentRequireContext = require.context("components", true)
const ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

require.context('images', true)

function setSafePadding() {
  document.querySelectorAll('.safe-padding')?.forEach(node => {
    if (window.orientation == 90) { // notch is on the left
      node.classList.add('safe-padding-left')
      node.classList.remove('safe-padding-right')
    } else if (window.orientation == -90) { // notch on the right
      node.classList.remove('safe-padding-left')
      node.classList.add('safe-padding-right')
    } else {
      node.classList.remove('safe-padding-left')
      node.classList.remove('safe-padding-right')
    }
  })
}

const Headroom = require("headroom.js")
document.addEventListener("DOMContentLoaded", function() {
  setSafePadding()
  const navbar = document.querySelector(".navbar")
  if (navbar) {
    new Headroom(navbar, {
      "classes": {
        "initial": "animated",
        "pinned": "slideDown",
        "unpinned": "slideUp"
      }
    }).init()

    if (window.location.hash) {
      setTimeout(() => {
        document.getElementById(window.location.hash.slice(1))?.scrollIntoView()
        navbar.classList.add("slideUp")
      }, 1000)
    }
  }
})

window.addEventListener("orientationchange", setSafePadding)
